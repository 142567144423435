import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';

import { SlackChannel } from 'src/models';

import { ChannelSelectorModalComponent } from './channel-selector-modal/channel-selector-modal.component';

@Component({
  selector: 'app-channel-selector',
  templateUrl: './channel-selector.component.html',
  styleUrls: ['./channel-selector.component.css'],
})
export class ChannelSelectorComponent {
  @Input() channel?: SlackChannel;

  @Input()
  public initialButtonText = 'Link to a channel';

  @Input() public defaultInternalOnly = false;

  @Input() public defaultExternalOnly = false;

  @Input() public hideChannelTypeFilter = false;

  @Input() public hideSecondaryTeams = true;

  @Input() public hideUnlinkButton = false;

  @Input() public hideLinkedToText = false;

  @Output() channelDidChange = new EventEmitter<SlackChannel>();

  constructor(private modalCtrl: ModalController) {}

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: ChannelSelectorModalComponent,
      componentProps: {
        selectedChannel: this.channel,
        defaultInternalOnly: this.defaultInternalOnly,
        defaultExternalOnly: this.defaultExternalOnly,
        hideChannelTypeFilter: this.hideChannelTypeFilter,
        hideSecondaryTeams: this.hideSecondaryTeams,
      },
    });

    modal.present();

    modal.onDidDismiss().then(({ data }: any) => {
      if (data?.channel) {
        this.setChannel(data.channel);
      }
    });
  }

  clear() {
    this.setChannel(undefined);
  }

  setChannel(channel?: SlackChannel) {
    this.channel = channel;
    this.channelDidChange.emit(this.channel);
  }
}
