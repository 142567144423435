import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import _ from 'lodash';

import { delay } from 'src/app/utils/utils';
import { SlackChannel } from 'src/models';
import { MessageService } from 'src/services/message.service';
import { SlackService } from 'src/services/slack.service';

import { ActionDropdownComponent, ActionDropdownType } from '../../action-dropdown/action-dropdown.component';

@Component({
  selector: 'app-channel-selector-modal',
  templateUrl: './channel-selector-modal.component.html',
  styleUrls: ['./channel-selector-modal.component.css'],
})
export class ChannelSelectorModalComponent implements OnInit {
  @Input()
  public selectedChannel?: SlackChannel;

  @Input()
  public defaultInternalOnly = false;

  @Input()
  public defaultExternalOnly = false;

  @Input()
  public hideChannelTypeFilter = false;

  @Input()
  public hideSecondaryTeams = true;

  public selectedChannelId?: string;
  public channels: SlackChannel[];
  public connectedTeams: any[];
  public selectedTeamId: string;
  public filteredChannels: SlackChannel[];
  public userConnectedToSlack = false;
  public teamConnectedToSlack = false;
  public isLoading: boolean;
  public isLoadingChannels: boolean;
  public isOpen = true;
  public isConnecting = false;
  public searchText: string;
  public filterType: 'all' | 'shared' | 'internal' = 'all';

  constructor(
    private modalCtrl: ModalController,
    private msgService: MessageService,
    private slackService: SlackService,
    private popoverCtrl: PopoverController,
    private loadingCtrl: LoadingController,
  ) {}

  async ngOnInit() {
    this.selectedChannelId = this.selectedChannel?.id;

    if (this.defaultExternalOnly) {
      this.filterType = 'shared';
    } else if (this.defaultInternalOnly && !this.selectedChannel?.isShared) {
      this.filterType = 'internal';
    }

    this.isLoading = true;

    this.connectedTeams = await this.slackService.getConnectedTeams();
    this.selectedTeamId = this.connectedTeams[0]?.id;

    await this.loadChannels();

    this.isLoading = false;
  }

  async loadChannels() {
    this.isLoadingChannels = true;

    const [teamScopes, userScopes] = await Promise.all([
      this.slackService.getScopes(this.selectedTeamId),
      this.slackService.getUserScopes(this.selectedTeamId),
    ]);

    this.teamConnectedToSlack = teamScopes.exists;
    this.userConnectedToSlack =
      userScopes.exists && !(await this.slackService.requiresUserScope(undefined, this.selectedTeamId));

    if (this.teamConnectedToSlack) {
      if (this.userConnectedToSlack) {
        this.channels = await this.slackService.getChannelsForUser(true, this.selectedTeamId);
      } else {
        const promises = [this.slackService.getConnectedChannels(true, this.selectedTeamId)];

        if (teamScopes.scopes['channels:join']) {
          promises.push(this.slackService.getPublicChannels(true, this.selectedTeamId));
        } else {
          promises.push(Promise.resolve([]));
        }

        const [connectedChannels, publicChannels] = await Promise.all(promises);

        this.channels = _.unionBy(connectedChannels, publicChannels, 'id');
      }

      this.handleFilters();
    }

    this.isLoadingChannels = false;
  }

  dismiss() {
    this.isOpen = false;
    this.modalCtrl.dismiss();
  }

  async selectChannel(channel: SlackChannel) {
    this.selectedChannelId = channel.id;
    this.selectedChannel = channel;

    if (this.selectedChannel?.id) {
      const loader = await this.loadingCtrl.create({
        message: 'Connecting...',
        spinner: 'crescent',
      });

      loader.present();
      try {
        await this.slackService.connectChannel(this.selectedChannel.id, this.selectedChannel.teamId);
        loader.dismiss();
      } catch (error) {
        this.msgService.showError(error);
        loader.dismiss();

        return;
      }
    }

    this.isOpen = false;
    this.modalCtrl.dismiss({ channel: this.selectedChannel });
  }

  async connectUserToSlack() {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    this.slackService.userOauth(
      `${window.location.origin}/close-window`,
      window.location.origin,
      !this.connectedTeams.find((team) => team.id === this.selectedTeamId).isPrimary,
    );

    this.isLoading = true;

    await delay(5000);

    while (this.isOpen && !this.userConnectedToSlack) {
      await this.loadChannels();
      await delay(2000);
    }

    this.isConnecting = false;
    this.isLoading = false;
  }

  connectTeamToSlack() {
    this.slackService.oauth(
      window.location.href,
      window.location.origin,
      !(this.connectedTeams.find((team) => team.id === this.selectedTeamId)?.isPrimary ?? true),
    );
  }

  handleFilters() {
    const term = this.searchText?.toLowerCase();

    this.filteredChannels = this.channels.filter((channel) => {
      let passSearch = !term;
      let passFilter = this.filterType === 'all';

      if (term) {
        passSearch = channel.name.toLowerCase().includes(term);
      }

      if (this.filterType === 'shared') {
        passFilter = channel.isShared;
      } else if (this.filterType === 'internal') {
        passFilter = !channel.isShared;
      }

      return passSearch && passFilter;
    });
  }

  async changeFilter() {
    const options: ActionDropdownType[] = [
      {
        label: 'All',
        icon: this.filterType === 'all' ? 'checkmark' : 'yo',
      },
      {
        label: 'Internal only',
        icon: this.filterType === 'internal' ? 'checkmark' : 'yo',
      },
      {
        label: 'Shared only',
        icon: this.filterType === 'shared' ? 'checkmark' : 'yo',
      },
    ];

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (idx: number) => {
          popover.dismiss();

          if (idx === 0) {
            this.filterType = 'all';
          } else if (idx === 1) {
            this.filterType = 'internal';
          } else if (idx === 2) {
            this.filterType = 'shared';
          }

          this.handleFilters();
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }
}
