<div>
  <ng-container *ngIf="!channel?.id">
    <ion-button fill="outline" (click)="openModal()">
      <ion-icon name="unlink-outline" class="icon-padded"></ion-icon>
      {{ initialButtonText }}
    </ion-button>
  </ng-container>
  <ng-container *ngIf="channel?.id">
    <ion-button color="light" (click)="openModal()">
      <ion-icon name="link-outline" class="icon-padded"></ion-icon>
      {{ hideLinkedToText ? channel?.name : 'Linked to ' + channel?.name }}
    </ion-button>
    <ion-button color="danger" fill="clear" (click)="clear()" [hidden]="hideUnlinkButton"> Unlink </ion-button>
  </ng-container>
</div>
